'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { PiLinkThin } from 'react-icons/pi'
import { BiSolidError } from 'react-icons/bi'
import { useSwipeable } from 'react-swipeable'
import { toast } from 'sonner'
import { saveAs } from 'file-saver'
import { clientTrpc } from '@/src/lib/trpc'
import { useBpmStore } from '@/src/lib/bpmStore'
import { useKeyStore } from '@/src/lib/keyStore'
import { usePlayerStore } from '@/src/lib/playerStore'
import * as recording from '@/src/util/recorder'
import uploadBase64 from '@/src/util/uploadBase64'
import isUrlBase64 from '@/src/util/isUrlBase64'
import { useSkin } from '@/src/context/skin-provider'
import { useMixpanelContext } from '@/src/lib/mixpanel/useMixpanel'
import { Track, useTracks } from '@/src/context/audio-provider'
import { AnimationMachineProvider } from '@/src/context/animation-machine-provider'
import { SlapWithTracksAndSkinSerializable, SongData, PromptTemplate } from '@/src/types'
import { ActionMenu } from '@/src/components/actions'
import Loader from '@/src/components/Loader'
import { Progress } from '@/src/components/ui/progress'
import { copyAndToast } from '@/src/components/Toast'
import { PlusButton } from './PlusButton'
import Transport from './Transport'
import Pill from './Pill'
import { promptTemplates } from '../lib/prompt-templates'
import { useTrackQueue } from '../lib/trackQueueStore'

const Looper = ({ slap }: { slap: SlapWithTracksAndSkinSerializable | null }) => {
  const recorder = useRef<recording.Recorder | null>(null)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const isRequestingRef = useRef(false)
  const slapId = useRef<string | null>(null)

  const refPassthrough = (el: HTMLDivElement) => {
    // call useSwipeable ref prop with el
    handlers.ref(el)
    // set myRef el so you can access it yourself
    scrollRef.current = el
  }

  const { skin, setSkin } = useSkin()
  const mixpanel = useMixpanelContext()

  const {
    tracks,
    setTracks,
    playAll,
    stopAll,
    seekAll,
    pauseAll,
    toggleMuteTrack,
    deleteTrack,
    addNewTrack,
    lockedDuration,
    getPlayer,
    setLockedDuration,
    setAllTracksLoaded
  } = useTracks()

  const bpm = useBpmStore((state) => state.bpm)
  const currentBpm = useBpmStore((state) => state.currentBpm)
  const setBpm = useBpmStore((state) => state.setBpm)
  const setCurrentBpm = useBpmStore((state) => state.setCurrentBpm)

  const key = useKeyStore((state) => state.key)
  const currentKey = useKeyStore((state) => state.currentKey)
  const setKey = useKeyStore((state) => state.setKey)
  const setCurrentKey = useKeyStore((state) => state.setCurrentKey)
  const setKeyLocked = useKeyStore((state) => state.setKeyLocked)

  const getPlayerState = usePlayerStore.getState
  const isPlaying = usePlayerStore((state) => state.isPlaying)
  const wasPlayingWhenCountdownStarted = usePlayerStore((state) => state.wasPlayingWhenCountdownStarted)
  const setWasPlayingWhenCountdownStarted = usePlayerStore((state) => state.setWasPlayingWhenCountdownStarted)

  const handlers = useSwipeable({
    trackMouse: true
  })

  const defaultPromptTemplate = promptTemplates.find((pt: PromptTemplate) => pt.label === 'House')
  const [currentPrompt, setCurrentPrompt] = useState<string>(defaultPromptTemplate?.prompt || '')
  const [isLoading, setIsLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [isSplitting, setIsSplitting] = useState(false)
  const [progress, setProgress] = useState(0)
  const [mounted, setMounted] = useState(false)
  const [copying, setCopying] = useState(false) // eslint-disable-line unused-imports/no-unused-vars
  const [slapTitle, setSlapTitle] = useState<string | null>(null)
  const [isRecording, setIsRecording] = useState(false)
  const [selectedTrack, setSelectedTrack] = useState<Track | null>(null)
  const [downbeatTimes, setDownbeatTimes] = useState<number[]>([])
  const [countdown, setCountdown] = useState(0)
  const skipCountdownRef = useRef(false) // ref instead of state so can be accessed inside of setInterval
  const setSkipCountdown = (newValue: boolean) => {
    skipCountdownRef.current = newValue // Update the ref's current value
  }
  const { removeItem: removeItemFromTrackQueue } = useTrackQueue()
  const getQueueState = useTrackQueue.getState
  const [waitingToAddTrackQueue, setWaitingToAddTrackQueue] = useState(false)

  useEffect(() => {
    if (waitingToAddTrackQueue) {
      setTimeout(() => {
        stopAll()
        seekAll(0)
        if (getPlayerState().wasPlayingWhenCountdownStarted) {
          playAll()
          // setTimeout(playAll, 200)
        }
        setWaitingToAddTrackQueue(false)
      }, 200)
    }
  }, [tracks])

  const processQueue = async () => {
    const queue = getQueueState().items
    console.log('TRACK QUEUE BEING PROCESSED:', queue, 'wasPlaying...: ', wasPlayingWhenCountdownStarted)

    // Map each track in the queue to a Promise using the `addNewTrack` function
    const trackPromises = queue.map((trackToAdd, index) => {
      const { songData, title, sourceBpm, targetBpm, durationInMilliseconds } = trackToAdd
      // @ts-ignore
      return addNewTrack(songData, title, sourceBpm, targetBpm, durationInMilliseconds)
        .then((track) => {
          // Handle the successful addition of a track
          mixpanel.track({
            eventName: 'AddNewTrack',
            data: {
              currentPrompt,
              // @ts-ignore
              duration: durationInMilliseconds / 1000,
              bpm,
              currentBpm,
              key,
              title: slapTitle,
              skinId: skin.id,
              track: {
                id: track?.id,
                title: track?.title,
                isSoloed: track?.isSoloed,
                isMuted: track?.isMuted
              },
              trackLength: tracks.length,
              parentId: slapId.current
            }
          })
          return index // Return index to know which to remove
        })
        .catch((error) => {
          console.error('Error adding track:', error)
          throw index // Throw index to know which failed
        })
    })

    try {
      const completedIndices = await Promise.all(trackPromises)
      // Remove items from the queue that were successfully processed
      removeItemFromTrackQueue(completedIndices)
    } catch (failedIndex) {
      // Handle any failures, potentially retry or log
      console.error(`Failed to process track at index ${failedIndex}`)
    }

    setIsLoading(false)
  }

  const waitForTrackToEnd = () =>
    new Promise<void>((resolve) => {
      if (waitingToAddTrackQueue) {
        resolve()
        return
      }
      setWaitingToAddTrackQueue(true)
      console.log('SETIIN WAS PLAYING TO: ', getPlayerState().isPlaying)
      setWasPlayingWhenCountdownStarted(getPlayerState().isPlaying)

      const interval = setInterval(() => {
        const duration = tracks[0].player.duration()
        const currentTime = tracks[0].player.seek()
        setCountdown(Math.floor(duration - currentTime))

        if (currentTime / duration >= 0.95 || skipCountdownRef.current) {
          setCountdown(0)
          clearInterval(interval)
          setSkipCountdown(false)
          processQueue().then(() => {
            // stopAll()
            // seekAll(0)
            // if (getPlayerState().wasPlayingWhenCountdownStarted) {
            //   // playAll()
            //   setTimeout(playAll, 400)
            // }
            // setWaitingToAddTrackQueue(false)
            resolve()
          })
        }
      }, 100)
    })

  const update = async () => {
    if (isRequestingRef.current) return
    if (!bpm || !key || !lockedDuration || !slapTitle || tracks.length === 0) return

    mixpanel.track({
      eventName: 'ShareButtonClick',
      data: {
        currentPrompt,
        duration: lockedDuration,
        bpm,
        currentBpm,
        key,
        title: slapTitle,
        skinId: skin.id,
        trackLength: tracks.length,
        parentId: slapId.current
      }
    })

    isRequestingRef.current = true
    setCopying(true)
    toast(
      <div className="flex w-full items-center justify-between">
        <span>{'Preparing link...'}</span>
        <PiLinkThin />
      </div>
    )

    try {
      const uploadedTrackUrls = await Promise.all(
        tracks.map(async (track) => {
          if (isUrlBase64(track.src)) {
            const signedUrl = await uploadBase64(track.src)
            if (!signedUrl) throw new Error('Error: Could not create signed URLs when getting AppState.')
            return signedUrl
          }
          return track.src
        })
      )
      const appTracks = tracks.map((track, idx) => {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { player, pitchShiftNode, ...rest } = track
        rest.src = uploadedTrackUrls[idx]
        rest.data = rest.data.map((datum: any) => ({
          ...datum,
          audio: uploadedTrackUrls[idx]
        }))
        return rest
      })
      const slap = {
        // TODO: quick fix for share failing, wrong bpm type
        // @ts-ignore
        bpm: parseFloat(bpm),
        currentBpm,
        key,
        currentKey,
        duration: lockedDuration,
        title: slapTitle,
        skinId: skin.id,
        tracks: appTracks,
        parentId: slapId.current
      }

      const { id, slug } = await clientTrpc.slaps.create.mutate({
        slap: {
          ...slap,
          // TODO: There has to be cleaner way to handle this
          currentBpm: slap.currentBpm ?? undefined,
          currentKey: slap.currentKey ?? undefined,
          parentId: slap.parentId ?? undefined
        }
      })
      const shareURL = new URL(`${window.location.origin}/${slug ?? id}`)
      slapId.current = id
      copyAndToast(shareURL.href)
    } catch (error: any) {
      // todo: handle error
      console.error(error)
      toast(
        <div className="flex w-full justify-between">
          <span>Something went wrong 😕</span>
          <BiSolidError className="text-red-500" />
        </div>
      )
    } finally {
      setCopying(false)
      isRequestingRef.current = false
    }
  }

  const initSlap = useCallback(async () => {
    if (!slap) {
      setMounted(true)
      return
    }
    slapId.current = slap.id
    if (bpm !== slap.bpm) setBpm(slap.bpm)
    if (slap.currentBpm && slap.currentBpm !== slap.bpm) setCurrentBpm(slap.currentBpm)
    if (key !== slap.key) {
      setKey(slap.key)
      setKeyLocked(true)
    }
    const downbeatTimes =
      (slap.tracks[0]?.data[0] as SongData)?.downbeat_times || slap.tracks[0]?.data[0].downbeat_times
    if (downbeatTimes) setDownbeatTimes(downbeatTimes)
    setLockedDuration(slap.duration)
    setSlapTitle(slap.title)
    setSkin(slap.skin)

    const targetBpm = currentBpm || bpm
    const initTracks = slap.tracks.map((track: Omit<Track, 'player'>) => ({
      ...track,
      player: getPlayer(track.src, track.title, track.data[0].bpm, targetBpm)
    }))
    setTracks(initTracks)
    if (slap.currentKey && slap.currentKey !== slap.key) setCurrentKey(slap.currentKey)
    setMounted(true)
  }, [
    bpm,
    currentBpm,
    setBpm,
    setCurrentBpm,
    key,
    setKey,
    setKeyLocked,
    setLockedDuration,
    setSkin,
    setTracks,
    setCurrentKey,
    setAllTracksLoaded,
    tracks
  ])

  useEffect(() => {
    if (mounted) {
      return
    }

    if (!slap) {
      setMounted(true)
      return
    }
    initSlap()
  }, [initSlap, mounted])

  useEffect(() => {
    isLoading &&
      scrollRef.current?.scrollTo({
        top: scrollRef.current?.scrollHeight,
        behavior: 'smooth'
      })
  }, [isLoading])

  useEffect(() => {
    const interval = setInterval(() => {
      const nextProgress = progress >= 100 ? 100 : progress + 0.41
      setProgress(nextProgress)
      if (!isLoading) {
        setProgress(0)
        clearInterval(interval)
      }
    }, 50)
    return () => clearInterval(interval)
  }, [isLoading, progress])

  const startRecording = async () => {
    if (recorder.current) return // already recording
    // TODO: future feature: allow users to pipe in more audio using getUserMedia
    recorder.current = recording.createRecorder('wav')
    if (!isPlaying) playAll()
    recorder.current.start()
    setIsRecording(true)

    mixpanel.track({
      eventName: 'StartRecordingButtonClick',
      data: {
        currentPrompt,
        duration: lockedDuration,
        bpm,
        currentBpm,
        key,
        title: slapTitle,
        skinId: skin.id,
        trackLength: tracks.length,
        parentId: slapId.current
      }
    })
  }

  const stopRecording = async () => {
    if (!recorder.current) return // already stopped
    const inst = recorder.current
    recorder.current = null
    const wavBlob = await inst.stop()
    setIsRecording(false)
    saveAs(wavBlob, `${slapTitle ? slapTitle : 'untitled'}.wav`)

    mixpanel.track({
      eventName: 'StopRecordingButtonClick',
      data: {
        currentPrompt,
        duration: lockedDuration,
        bpm,
        currentBpm,
        key,
        title: slapTitle,
        skinId: skin.id,
        trackLength: tracks.length,
        parentId: slapId.current
      }
    })
  }
  const PlusButtonComponent = () => (
    <PlusButton
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      setIsGenerating={setIsGenerating}
      setSlapTitle={setSlapTitle}
      slapTitle={slapTitle}
      setProgress={setProgress}
      setDownbeatTimes={setDownbeatTimes}
      setIsSplitting={setIsSplitting}
      slapId={slapId}
      setCurrentPrompt={setCurrentPrompt}
      currentPrompt={currentPrompt}
      waitForTrackToEnd={waitForTrackToEnd}
    />
  )

  return !mounted ? (
    <Loader />
  ) : (
    <AnimationMachineProvider isPlaying={isPlaying} tracksLength={tracks.length} downbeatTimes={downbeatTimes}>
      <main
        className="flex h-screen w-full flex-col items-center gap-4 overflow-y-auto px-4 pt-8 sm:px-16"
        {...handlers}
        ref={refPassthrough}
      >
        <span className="text-md tracking-wides mt-6 uppercase">{slapTitle}</span>
        <div className="mb-24 w-full pb-32">
          {tracks.map((track, trackIdx) => (
            <div className="flex w-full flex-col" key={track.id}>
              <Pill
                track={track}
                trackIdx={trackIdx}
                isSelected={selectedTrack?.id === track.id}
                onSelect={(t: Track) => {
                  setSelectedTrack((prev) => (prev === t ? null : t))
                }}
                deleteTrack={() => {
                  deleteTrack(track)
                  mixpanel.track({
                    eventName: 'DeleteTrackButtonClick',
                    data: {
                      currentPrompt,
                      duration: lockedDuration,
                      bpm,
                      key,
                      currentBpm,
                      currentKey,
                      title: slapTitle,
                      skinId: skin.id,

                      track: {
                        id: track.id,
                        title: track.title,
                        isSoloed: track.isSoloed,
                        isMuted: track.isMuted
                      },
                      trackLength: tracks.length,
                      parentId: slapId.current
                    }
                  })
                }}
                toggleMuteTrack={() => {
                  toggleMuteTrack(track)
                  mixpanel.track({
                    eventName: 'ToggleMuteTrackButtonClick',
                    data: {
                      currentPrompt,
                      duration: lockedDuration,
                      bpm,
                      currentBpm,
                      key,
                      title: slapTitle,
                      skinId: skin.id,
                      currentKey,
                      track: {
                        id: track.id,
                        title: track.title,
                        isSoloed: track.isSoloed,
                        isMuted: track.isMuted
                      },
                      trackLength: tracks.length,
                      parentId: slapId.current
                    }
                  })
                }}
                onTrackActionComplete={(_t: Track) => {
                  mixpanel.track({
                    eventName: 'TrackActionComplete',
                    data: {
                      currentPrompt,
                      duration: lockedDuration,
                      bpm,
                      currentBpm,
                      key,
                      title: slapTitle,
                      skinId: skin.id,
                      currentKey,
                      track: {
                        id: _t.id,
                        title: _t.title,
                        isSoloed: _t.isSoloed,
                        isMuted: _t.isMuted
                      },
                      trackLength: tracks.length,
                      parentId: slapId.current
                    }
                  })
                  return undefined
                }}
              />
            </div>
          ))}

          {isLoading && (
            <Progress
              className="mt-4 h-12 w-full bg-transparent backdrop-blur-xl"
              value={progress}
              defaultValue={0}
              progressColor={skin.bgColor}
              text={
                countdown > 0 ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setSkipCountdown(true)
                    }}
                  >
                    Adding in {countdown} seconds... Click to Play Now
                  </div>
                ) : isSplitting ? (
                  'Splitting...'
                ) : isGenerating ? (
                  'Generating...'
                ) : (
                  'Loading...'
                )
              }
              textColor={skin.textColor}
            />
          )}
        </div>
        <div className="fixed bottom-0 z-50 flex w-screen flex-col items-center justify-center gap-6 bg-gradient-to-b from-black/0 to-black/80 pb-2 pt-2 text-center sm:pb-6">
          <Transport
            playAll={() => {
              playAll()
              mixpanel.track({
                eventName: 'PlayAll',
                data: {
                  currentPrompt,
                  duration: lockedDuration,
                  bpm,
                  currentBpm,
                  key,
                  title: slapTitle,
                  skinId: skin.id,
                  currentKey,
                  trackLength: tracks.length,
                  parentId: slapId.current
                }
              })
            }}
            pauseAll={() => {
              pauseAll()
              mixpanel.track({
                eventName: 'PauseAll',
                data: {
                  currentPrompt,
                  duration: lockedDuration,
                  bpm,
                  currentBpm,
                  key,
                  title: slapTitle,
                  skinId: skin.id,
                  currentKey,
                  trackLength: tracks.length,
                  parentId: slapId.current
                }
              })
            }}
            startRecording={startRecording}
            stopRecording={stopRecording}
            isRecording={isRecording}
            PlusButton={PlusButtonComponent}
          />
          <ActionMenu
            track={selectedTrack}
            deleteTrack={deleteTrack}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleShare={update}
            unselectTrack={() => {
              setSelectedTrack(null)
            }}
            waitForTrackToEnd={waitForTrackToEnd}
            // reset={reset}
          />
        </div>
      </main>
    </AnimationMachineProvider>
  )
}

export default Looper
