import React, { useEffect, useRef, useState } from 'react'
import { usePlayerStore } from '../lib/playerStore'
import { useAnimationMachine } from '../context/animation-machine-provider'

interface TrackWithPlayer {
  player: {
    seek: () => number
    duration: () => number
  }
}

export const ProgressBar = ({
  trackIdx,
  player,
  title
}: {
  trackIdx: number
  player: TrackWithPlayer['player']
  title: string
}) => {
  const requestRef = useRef<number | null>(null)
  const isPlaying = usePlayerStore((state) => state.isPlaying)
  const [progress, setProgress] = useState(0) // State to trigger re-renders and animate

  const { progressBarRefs } = useAnimationMachine()

  const animateProgress = () => {
    // TODO: look into always using sprites so that this can be consistent logic
    let duration = player.duration()
    let currentProgress
    // @ts-ignore
    const currentSprite = player._sprite[title]
    if (currentSprite) {
      // this is where I need to calculate the new duration, cuase of when bpm is set!
      const startPoint = currentSprite[0]
      duration = (currentSprite[1] - startPoint) / 1000
      // @ts-ignore
      const sounds = player._sounds
      if (sounds) {
        // @ts-ignore
        // console.log('PLAYER._SOUNDS', player._sounds)
        const lastSound = sounds.findLast((s: any) => s._sprite === title)
        // @ts-ignore
        if (lastSound) currentProgress = player.seek(null, lastSound._id) / duration
        // @ts-ignore
        else currentProgress = player.seek(null, sounds[0]._id) / duration
      } else {
        console.log('Something weird happening with player._sounds', player)
        currentProgress = player.seek() / duration
      }
    } else {
      currentProgress = player.seek() / duration
    }
    setProgress(currentProgress) // Update progress state
    requestRef.current = requestAnimationFrame(animateProgress) // Continue the animation loop
  }

  useEffect(() => {
    if (isPlaying) {
      requestRef.current = requestAnimationFrame(animateProgress)
    } else if (requestRef.current !== null) {
      cancelAnimationFrame(requestRef.current)
      requestRef.current = null
    }

    return () => {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current)
        requestRef.current = null
      }
    }
  }, [isPlaying])

  return (
    <div
      ref={(ref) => {
        if (!ref) return
        progressBarRefs.current.set(trackIdx, ref)
      }}
      className="absolute left-0 top-0 flex h-full items-center rounded-l-full bg-black/10"
      style={{ width: `${progress * 100}%` }}
    />
  )
}
