import { ChevronLeft } from 'lucide-react'
import { useSkin } from '../context/skin-provider'
import KeyLocker from './KeyLocker'

interface MenuBarProps {
  title: string
  onBack: () => void
  showBack: boolean
}

// TODO: text-wrap/spacing is a little off for smallest phones
const MenuBar = ({ title, onBack, showBack }: MenuBarProps) => {
  const { skin } = useSkin()

  return (
    <div
      className="flex items-center border-b border-gray-200 bg-gray-100 px-4 py-3"
      style={{
        backgroundColor: skin.bgColor,
        color: skin.textColor
      }}
    >
      {/* Left section */}
      <div className="flex-1">
        {showBack && (
          <button onClick={onBack} className="flex items-center font-semibold">
            <ChevronLeft size={20} />
            <span>Back</span>
          </button>
        )}
      </div>

      {/* Center section */}
      <div className="flex-1 text-wrap text-center">
        <h1 className="text-wrap text-lg font-semibold" title={title}>
          {title}
        </h1>
      </div>

      {/* Right section */}
      <div className="flex-1">
        <KeyLocker />
      </div>
    </div>
  )
}

export default MenuBar
