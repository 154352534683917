import React, { useEffect, useState } from 'react'
import Modal from './Modal'

interface FirstVisitModalProps {
  modalKey: string
  onClose?: () => void
  [key: string]: any
}

const FirstVisitModal: React.FC<FirstVisitModalProps> = ({ modalKey, onClose, ...props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    const hasSeenModal = localStorage.getItem(modalKey)
    if (!hasSeenModal) {
      setIsOpen(true)
      localStorage.setItem(modalKey, 'false')
    }
  }, [modalKey])

  const handleClose = () => {
    setIsOpen(false)
    if (onClose) {
      onClose()
    }
  }

  return <Modal isOpen={isOpen} setIsOpen={setIsOpen} {...props} onClose={handleClose} />
}

export default FirstVisitModal
