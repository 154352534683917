import Modal from '@/src/components/Modal'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { SongData, ActionItem } from '@/src/types'
import { Track, useTracks } from '@/src/context/audio-provider'
import { cn, downloadAsZip } from '@/src/lib/utils'
import { useSkin } from '@/src/context/skin-provider'
import {
  PiCopyThin,
  PiDownloadThin,
  PiFadersThin,
  PiHeartbeatThin,
  PiPianoKeysThin,
  PiShareThin,
  PiTrashThin
} from 'react-icons/pi'

import { Spinner } from '@/src/components/Loader'
import { saveAs } from 'file-saver'
import { useMixpanelContext } from '@/src/lib/mixpanel/useMixpanel'
import { copyAndToast } from '@/src/components/Toast'

import { StyleTransferButtons, StyleTransferTemplate } from './PromptTemplateButtons'
import { runpod } from '@/src/lib/runpod'
import { VANILLA_MUSICGEN_ID } from '@/src/lib/endpoints'
import jobFinish from '@/src/util/jobFinish'
import { isEndpointCompletedOutput } from '@/src/types'
import { FiZap } from 'react-icons/fi'
import { VBox } from 'intuitive-flexbox'
import BpmSlider from '@/src/components/BpmSlider'
import KeySelector from '@/src/components/KeySelector'
import { clientTrpc } from '../lib/trpc'
import { useBpmStore } from '../lib/bpmStore'
import { QueuedTrack, useTrackQueue } from '../lib/trackQueueStore'
import { usePlayerStore } from '../lib/playerStore'

const ActionModal = ({
  isOpen,
  setIsOpen,
  selectedAction,
  selectedTrack,
  handleClose,
  isLoading,
  setIsLoading,
  waitForTrackToEnd
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  selectedAction: ActionItem
  selectedTrack: Track | null
  handleClose: () => void
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  waitForTrackToEnd: () => Promise<void>
}) => (
  <Modal
    setStatus={null}
    onClose={handleClose}
    className={cn('flex flex-col justify-start gap-6')}
    title={selectedAction?.description}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
  >
    {!!selectedAction?.Component && (
      <selectedAction.Component
        track={selectedTrack}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsOpen={setIsOpen}
        waitForTrackToEnd={waitForTrackToEnd}
      />
    )}
  </Modal>
)

export const ActionMenu = ({
  deleteTrack,
  track,
  isLoading,
  setIsLoading,
  handleShare,
  unselectTrack,
  waitForTrackToEnd
  // reset
}: {
  deleteTrack: (track: Track) => void
  track: Track | null
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  handleShare: () => void
  unselectTrack: (t: Track) => void
  waitForTrackToEnd: () => Promise<void>
  // reset: () => void
}) => {
  const { skin } = useSkin()
  const { tracks } = useTracks()
  const mixpanel = useMixpanelContext()
  const [loading] = useState<string[]>([])
  const [selectedAction, setSelectedAction] = useState<ActionItem | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const actions: ActionItem[] = [
    // Remix/Style Transfer has issues with the countdown, and its not a core feature,
    // so disabling for now
    // track actions
    // {
    //   id: 'remix',
    //   name: 'Remix',
    //   description: 'Remix the selected audio track with a new style',
    //   scopes: ['track'],
    //   Icon: PiArrowsClockwiseThin,
    //   execute: undefined,
    //   Component: StyleTransfer
    // },
    {
      id: 'download',
      name: 'Download',
      description: 'Download the selected audio track',
      scopes: ['track'],
      Icon: PiDownloadThin,
      execute: async (t: Track | null) => {
        if (!t) return
        try {
          const response = await fetch(t.src)
          if (!response.ok) {
            throw new Error('Fetch of track url failed; Network response was not ok')
          }
          const arrayBuffer = await response.arrayBuffer()
          const file = new Blob([arrayBuffer], { type: 'audio/mpeg' })
          saveAs(file, t.title)
        } catch (error) {
          console.error('Fetch error:', error)
        }
        mixpanel.track({
          eventName: 'DownloadTrack',
          data: {
            duration: track?.data[0].duration,
            bpm: track?.data[0].bpm,
            key: track?.data[0].key,
            skin
          }
        })
      }
    },
    {
      id: 'copy-url',
      name: 'Copy URL',
      description: 'Copy the URL of the selected audio track',
      scopes: ['track'],
      Icon: PiCopyThin,
      execute: (t: Track | null) => {
        if (!t) return
        console.log('clicked')
        copyAndToast(t.src)
        mixpanel.track({
          eventName: 'CopyURL',
          data: {
            duration: track?.data[0].duration,
            bpm: track?.data[0].bpm,
            key: track?.data[0].key,
            skin
          }
        })
      }
    },
    {
      id: 'delete',
      name: 'Delete',
      description: 'Delete the selected audio track',
      scopes: ['track'],
      Icon: PiTrashThin,
      execute: (t: Track | null) => {
        if (!t) return
        unselectTrack(t)
        deleteTrack(t)
        mixpanel.track({
          eventName: 'DeleteTrack',
          data: {
            duration: track?.data[0].duration,
            bpm: track?.data[0].bpm,
            key: track?.data[0].key,
            skin
          }
        })
      }
    },
    // global actions
    {
      id: 'update-bpm',
      name: 'BPM',
      description: 'Update the BPM of the global audio',
      scopes: ['global'],
      Icon: PiHeartbeatThin,
      execute: undefined,
      Component: BpmSlider
    },
    {
      id: 'update-key',
      name: 'Key',
      description: 'Update the Key of the global audio',
      scopes: ['global'],
      Icon: PiPianoKeysThin,
      execute: undefined,
      Component: KeySelector
    },
    {
      id: 'share',
      name: 'Share',
      description: 'Share a link to the slap',
      scopes: ['global'],
      Icon: PiShareThin,
      execute: handleShare
    },
    {
      id: 'download-all',
      name: 'Download',
      description: 'Download all audio tracks',
      scopes: ['global'],
      Icon: PiDownloadThin,
      execute: async () => {
        if (!tracks.length) return
        const mappedTracks = await Promise.all(
          tracks.map(async (track) => {
            const data = await fetch(track.src).then((response) => response.blob())
            return {
              name: track.title,
              data
            }
          })
        )
        const filteredTracks = mappedTracks.filter((track) => !!track?.data) || []
        downloadAsZip(filteredTracks, 'tracks')
      }
    },
    {
      id: 'fx',
      name: 'FX',
      description: 'Apply effects to the global audio',
      scopes: ['global'],
      Icon: PiFadersThin,
      Component: ComingSoon
    }
    // {
    //   id: 'reset',
    //   name: 'Reset',
    //   description: 'Reset the global audio',
    //   scopes: ['global'],
    //   Icon: PiTrashThin,
    //   execute: reset
    // }
  ]

  const scopedActions = actions.filter((action) => action.scopes.includes(track ? 'track' : 'global'))
  return (
    <div className="z-50 flex h-full w-full items-center justify-center">
      <div
        className="flex items-center justify-center gap-10 rounded p-5 backdrop-blur-xl"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0)'
        }}
      >
        {scopedActions.map((action) => {
          const isLoading = loading.some((l) => l === `${action.id}-${track?.id}`)
          const execute = action.execute
          return (
            <button
              key={action.id}
              title={action.name}
              onClick={
                execute
                  ? () => execute(track)
                  : () => {
                      setSelectedAction(action)
                      setIsOpen(true)
                    }
              }
              className="flex w-fit flex-col items-center gap-3 text-white"
              style={{
                color: skin.textColor,
                borderColor: skin.textColor
              }}
            >
              {isLoading ? <Spinner className="h-6 w-6" /> : <action.Icon size={24} className="text-white" />}
              <span className="text-[8px] uppercase tracking-widest text-white">{action.name}</span>
            </button>
          )
        })}

        {isOpen && selectedAction && (
          <ActionModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedAction={selectedAction}
            selectedTrack={track}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleClose={() => {
              setIsOpen(false)
            }}
            waitForTrackToEnd={waitForTrackToEnd}
          />
        )}
      </div>
    </div>
  )
}

const StyleTransfer = ({
  track,
  isLoading,
  setIsLoading,
  setIsOpen,
  waitForTrackToEnd
}: {
  track: Track | null
  isLoading: boolean
  setIsLoading: (loading: boolean) => void
  setIsOpen: Dispatch<SetStateAction<boolean>>
  waitForTrackToEnd: () => Promise<void>
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const { skin } = useSkin()
  const [transPrompt, setTransPrompt] = useState('')
  const mixpanel = useMixpanelContext()
  const getBpmState = useBpmStore.getState
  const isPlaying = usePlayerStore((state) => state.isPlaying)

  const { tracks, addNewTrack, lockedDuration } = useTracks()
  const { addItem: addItemToTrackQueue } = useTrackQueue()

  return (
    <VBox className="gap-4">
      <textarea
        ref={textareaRef}
        onChange={(e) => {
          setTransPrompt(e.target.value)
        }}
        value={transPrompt}
        cols={30}
        rows={4}
        className="rounded bg-transparent p-2 outline-primary-foreground"
        style={{
          outlineColor: skin.textColor
        }}
        placeholder="Enter a prompt to modify the current track"
      />
      <StyleTransferButtons
        onClick={(pt: StyleTransferTemplate) => {
          setTransPrompt(pt.prompt)
          !!textareaRef.current && textareaRef.current.focus()
        }}
      />
      <button
        className="flex w-full items-center justify-center gap-2 rounded-full bg-white px-2 py-1 font-medium text-primary disabled:cursor-not-allowed disabled:opacity-30"
        disabled={!transPrompt || isLoading}
        onClick={async () => {
          setIsLoading(true)
          setIsOpen(false)
          try {
            if (!track) {
              throw new Error('No track selected')
            }
            const transJobID = await clientTrpc.musicGen.styleTransfer.mutate({
              prompt: transPrompt,
              audioPrompts: [track?.src],
              duration: track?.player.duration()
            })
            if (!transJobID) {
              throw new Error('Runpod job did not start successfully')
            }

            const genEndpoint = runpod.endpoint(VANILLA_MUSICGEN_ID)
            const resp = await jobFinish({
              func: async () => genEndpoint?.status(transJobID),
              condition: (resp) => {
                if (resp?.status === 'FAILED') console.error('Error with style transfer: ', resp)
                return resp?.status === 'COMPLETED' || resp?.status === 'FAILED'
              },
              retry: -Infinity
            })
            if (!isEndpointCompletedOutput(resp)) {
              console.error(resp)
              throw new Error('Runpod job did not complete successfully')
            }
            const data: SongData[] = resp.output.outputs
            const targetBpm = getBpmState().currentBpm || getBpmState().bpm
            const durationInMilliseconds = lockedDuration ? lockedDuration * 1000 : undefined
            if (tracks.length && isPlaying) {
              const newTrackArgs: QueuedTrack = {
                songData: data,
                title: 'New',
                sourceBpm: track?.data[0].bpm,
                targetBpm
              }
              if (durationInMilliseconds) newTrackArgs.durationInMilliseconds = durationInMilliseconds
              addItemToTrackQueue(newTrackArgs)
              waitForTrackToEnd()
            } else {
              addNewTrack(data, 'New', data[0].bpm, targetBpm, durationInMilliseconds)
              // onTrackActionComplete(t)
              mixpanel.track({
                eventName: 'StyleTransferSuccess',
                data: {
                  transPrompt,
                  duration: track?.data[0].duration,
                  bpm: track?.data[0].bpm,
                  key: track?.data[0].key,
                  skin
                }
              })
              setIsLoading(false)
            }
          } catch (e) {
            console.error(e)
          }
        }}
      >
        <span>Generate</span>
        <FiZap />
      </button>
    </VBox>
  )
}

const ComingSoon = () => <div className="text-primary">Coming Soon!</div>
