import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSkin } from '@/src/context/skin-provider'
import { motion, AnimatePresence } from 'framer-motion'
import MenuBar from './MenuBar'
import GenreList from './GenreList'
import CategoryList from './CategoryList'
import SampleList, { SampleListRef } from './SampleList'
import { InfiniteData } from '@tanstack/react-query'
import { GenreListItem, Sample } from '../types'
import { useFilteredData, useGenreList, useSampleList } from '../lib/exploreHooks'
import { slideVariants, getViewHeight } from '../lib/slideAnimations'
import { nextTrpc } from '../lib/trpc'
import { useKeyStore } from '../lib/keyStore'

interface GenreSampleBrowserProps {
  onAdd: (sample: Sample) => void
}

const GenreSampleBrowser: React.FC<GenreSampleBrowserProps> = ({ onAdd }) => {
  const { skin } = useSkin()
  const key = useKeyStore((state) => state.key)
  const keyLocked = useKeyStore((state) => state.keyLocked)
  const [view, setView] = useState<'genres' | 'categories' | 'samples'>('genres')
  const [direction, setDirection] = useState<'forward' | 'backward'>('forward')
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedGenre, setSelectedGenre] = useState<GenreListItem | null>(null)
  const [selectedCategory, setSelectedCategory] = useState<'drums' | 'songstarter' | null>(null)
  const sampleListRef = useRef<SampleListRef>(null)

  const stopAllPreviewAudio = () => {
    sampleListRef.current?.stopAllAudio()
  }

  const unloadPreviewAudio = () => {
    sampleListRef.current?.unloadAudio()
  }

  const getTitle = () => {
    switch (view) {
      case 'genres':
        return 'Explore Genres'
      case 'categories':
        return selectedGenre?.name || 'Select Category'
      case 'samples':
        return `${selectedGenre?.name} - ${selectedCategory}`
    }
  }

  const {
    genreListData,
    loadMoreGenres,
    hasGenreNextPage,
    isFetchingNextGenrePage,
    updateGenreListData,
    clearSearch: clearGenreSearch,
    isSearching: isSearchingGenres,
    isInitialLoading: isInitialLoadingGenres
  } = useGenreList(view)

  const {
    sampleListData,
    loadMoreSamples,
    hasSampleNextPage,
    isFetchingNextSamplePage,
    updateSampleListData,
    clearSearch: clearSampleSearch,
    isSearching: isSearchingSamples,
    isInitialLoading: isInitialLoadingSamples
  } = useSampleList(selectedGenre, selectedCategory, view)

  const filteredSamples = useFilteredData<Sample>(
    sampleListData as InfiniteData<{ items: Sample[]; nextCursor: string | undefined }> | undefined,
    searchTerm
  )
  const filteredGenres = useFilteredData<GenreListItem>(
    genreListData as InfiniteData<{ items: GenreListItem[]; nextCursor: string | undefined }> | undefined,
    searchTerm
  )

  const handleGenreSelect = (genre: GenreListItem) => {
    setSelectedGenre(genre)
    setDirection('forward')
    setView('categories')
  }

  const handleCategorySelect = (category: 'drums' | 'songstarter') => {
    setSelectedCategory(category)
    setDirection('forward')
    clearSampleSearch()
    setView('samples')
  }

  const handleBack = () => {
    setDirection('backward')
    if (view === 'samples') {
      stopAllPreviewAudio()
      unloadPreviewAudio()
      clearSampleSearch()
      setView('categories')
      setSelectedCategory(null)
    } else if (view === 'categories') {
      clearGenreSearch()
      setView('genres')
      setSelectedGenre(null)
    }
    setSearchTerm('')
  }

  const serverSearchGenresMutation = nextTrpc.loops.searchGenres.useMutation()
  const serverSearchSamplesMutation = nextTrpc.loops.searchSamples.useMutation()

  const handleSearch = useCallback(
    async (term: string) => {
      setSearchTerm(term)

      if (view === 'genres') {
        if (term.trim() === '') {
          clearGenreSearch()
        } else {
          try {
            const results = await serverSearchGenresMutation.mutateAsync({
              searchTerm: term,
              key: keyLocked ? key : undefined
            })
            updateGenreListData(results)
          } catch (error) {
            console.error('Error performing server-side genre search:', error)
          }
        }
      } else if (view === 'samples' && selectedGenre && selectedCategory) {
        if (term.trim() === '') {
          clearSampleSearch()
        } else {
          try {
            const results = await serverSearchSamplesMutation.mutateAsync({
              genre: selectedGenre.name,
              instrument: selectedCategory,
              searchTerm: term,
              key: keyLocked ? key : undefined
            })
            console.log('RESULTS: ', results)
            updateSampleListData(results)
          } catch (error) {
            console.error('Error performing server-side genre search:', error)
          }
        }
      }
    },
    [
      view,
      clearGenreSearch,
      clearSampleSearch,
      serverSearchGenresMutation,
      serverSearchSamplesMutation,
      updateGenreListData,
      updateSampleListData
    ]
  )

  // cleanup
  useEffect(
    () => () => {
      stopAllPreviewAudio()
    },
    [stopAllPreviewAudio]
  )

  return (
    <div
      className="flex h-screen flex-col outline-none"
      style={{
        backgroundColor: skin.bgColor,
        color: skin.textColor
      }}
    >
      <MenuBar title={getTitle()} onBack={handleBack} showBack={view !== 'genres'} />
      <div className="relative flex-grow overflow-hidden overflow-y-auto">
        <AnimatePresence initial={false} custom={direction}>
          {view === 'genres' && (
            <motion.div
              key="genres"
              custom={direction}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ type: 'tween', duration: 0.3 }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: getViewHeight('genres')
              }}
            >
              <GenreList
                genres={filteredGenres}
                onGenreSelect={handleGenreSelect}
                onSearch={handleSearch}
                loadMoreGenres={loadMoreGenres}
                hasMoreGenres={!isSearchingGenres && !!hasGenreNextPage}
                isInitialLoading={isInitialLoadingGenres}
                isLoadingGenres={isFetchingNextGenrePage}
                isSearching={isSearchingGenres}
              />
            </motion.div>
          )}
          {view === 'categories' && selectedGenre && (
            <motion.div
              key="categories"
              custom={direction}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ type: 'tween', duration: 0.3 }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: getViewHeight('categories')
              }}
            >
              <CategoryList
                onCategorySelect={handleCategorySelect}
                drumCount={selectedGenre.drumCount}
                songstarterCount={selectedGenre.songstarterCount}
              />
            </motion.div>
          )}
          {view === 'samples' && selectedGenre && (
            <motion.div
              key="samples"
              custom={direction}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ type: 'tween', duration: 0.3 }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: getViewHeight('samples')
              }}
            >
              <SampleList
                ref={sampleListRef}
                samples={filteredSamples}
                onSearch={handleSearch}
                onAdd={onAdd}
                loadMoreSamples={loadMoreSamples}
                hasMoreSamples={!isSearchingSamples && !!hasSampleNextPage}
                isLoadingSamples={isFetchingNextSamplePage}
                isInitialLoading={isInitialLoadingSamples}
                isSearching={isSearchingSamples}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default GenreSampleBrowser
