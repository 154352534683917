import { Variants } from 'framer-motion'

export const slideVariants: Variants = {
  enter: (direction: 'forward' | 'backward') => ({
    x: direction === 'forward' ? '100%' : '-100%',
    opacity: 0
  }),
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction: 'forward' | 'backward') => ({
    x: direction === 'forward' ? '-100%' : '100%',
    opacity: 0
  })
}

export const getViewHeight = (viewType: 'genres' | 'categories' | 'samples'): string => {
  switch (viewType) {
    case 'genres':
      return 'calc(100% - 56px)' // MenuBar height is approx 56px for genres view (no back btn)
    case 'categories':
    case 'samples':
      return 'calc(100% - 80px)' // MenuBar height is 80px for categories and samples views
    default:
      return '100%'
  }
}

export const fadeVariants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 }
}

export const scaleVariants: Variants = {
  hidden: { scale: 0.8, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 }
}

export const combineVariants = (...variants: Variants[]): Variants =>
  variants.reduce((combined, variant) => {
    Object.keys(variant).forEach((key) => {
      combined[key] = { ...combined[key], ...variant[key] }
    })
    return combined
  }, {})
