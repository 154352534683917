import TableViewCell from './TableViewCell'

interface CategoryListProps {
  onCategorySelect: (category: 'drums' | 'songstarter') => void
  drumCount: bigint
  songstarterCount: bigint
}

const CategoryList = ({ onCategorySelect, drumCount, songstarterCount }: CategoryListProps) => (
  <div className="flex-grow overflow-y-auto">
    <TableViewCell
      title="Drums"
      subtitle={`${drumCount} samples`}
      onPress={() => onCategorySelect('drums')}
      showPlay={false}
      showAdd={false}
      disabled={drumCount === BigInt(0)}
    />
    <TableViewCell
      title="Songstarters"
      subtitle={`${songstarterCount} samples`}
      onPress={() => onCategorySelect('songstarter')}
      showPlay={false}
      showAdd={false}
      disabled={songstarterCount === BigInt(0)}
    />
  </div>
)

export default CategoryList
