import { PromptTemplate } from '../types'

// maybe allow bpm ranges or key: "minor or major"
export const promptTemplates: PromptTemplate[] = [
  {
    prompt: 'soulful house music, minor 7th chords, rhodes piano, deep bassline, crispy analog percussion',
    label: 'House',
    key: 'F#min',
    bpm: 120,
    samples: []
  },
  {
    prompt: 'ominous pad, plucky piano, minor triad, minor arpeggio, heavy reverb',
    label: 'Dark/Evil',
    key: 'Cmin',
    bpm: 70,
    samples: []
  },
  {
    prompt: 'wah guitar, heavy delay, flanger, Rhodes, synth lead',
    label: 'Psychedelic',
    key: 'F#min',
    bpm: 90,
    samples: []
  },
  {
    prompt: 'rhythmic wah guitar, electric piano, staccato bassline, horn stabs',
    label: 'Funky',
    key: 'F#maj',
    bpm: 92,
    samples: []
  },
  {
    prompt: '7th chord Piano, 7th chord synth pads, guitar with heavy delay, staccato 7th chord strings',
    label: 'Soulful/R&B',
    key: 'Bbmin',
    bpm: 85,
    samples: []
  },
  {
    prompt: 'minor 9th chord stab, staccato bassline, chopped vocal sample, quantized muted plucky synth',
    label: 'House II',
    key: 'Amin',
    bpm: 120,
    samples: []
  },
  ///////////////////////////
  {
    prompt: 'soulful hip hop music, minor 7th chords, rhodes piano, deep bassline, crispy analog percussion',
    label: 'Hip-Hop',
    key: 'F#min',
    bpm: 120,
    samples: []
  },
  {
    prompt: 'Trap Hip Hop music, minor 7th chords, rhodes piano, deep bassline, crispy analog percussion',
    label: 'Trap',
    key: 'F#min',
    bpm: 120,
    samples: []
  }
]
