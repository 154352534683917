import { cn } from '@/src/lib/utils'
import { PromptTemplate } from '@/src/types'
import { promptTemplates } from '@/src/lib/prompt-templates'
import { useState } from 'react'
import chroma from 'chroma-js'
import { useSkin } from '../context/skin-provider'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select'

const PromptTemplateButtons = ({ onClick }: { onClick: (pt: PromptTemplate) => void }) => {
  const { skin } = useSkin()
  const [activeLabel, setActiveLabel] = useState<string>('House')
  const activeStateTextColor = chroma(skin.textColor).brighten(1).css()
  const activeStateBgColor = chroma(skin.bgColor).brighten(0.5).css()

  return (
    <div id="pt-buttons" className="flex flex-wrap gap-3 bg-transparent sm:gap-4 sm:text-base">
      {promptTemplates.map((pt) => {
        const { label } = pt
        return (
          <button
            title={`Use "${label}" preset`}
            key={`template-${label}`}
            onClick={() => {
              setActiveLabel(label)
              onClick(pt)
            }}
            className={cn('rounded bg-white px-2 text-black')}
            style={{
              backgroundColor: activeLabel === label ? activeStateBgColor : 'white',
              color: activeLabel === label ? activeStateTextColor : 'black'
            }}
          >
            {label}
          </button>
        )
      })}
    </div>
  )
}

export const PromptTemplateSelector = ({ onClick }: { onClick: (pt: PromptTemplate) => void }) => {
  const { skin } = useSkin()
  const [selectedTemplate, setSelectedTemplate] = useState<PromptTemplate>(promptTemplates[0])

  return (
    <div className="text-center">
      <label className="text-xs">Genre 💿</label>
      <Select
        value={selectedTemplate.label}
        onValueChange={(value) => {
          const selectedPt = promptTemplates.find((pt) => pt.label === value)
          if (selectedPt) {
            setSelectedTemplate(selectedPt)
            onClick(selectedPt)
          }
        }}
      >
        <SelectTrigger
          title="Choose a genre"
          className="w-full"
          style={{
            backgroundColor: skin.bgColor, // This applies your skin background color to the Trigger
            color: skin.textColor // This applies your skin text color to the Trigger
          }}
        >
          <SelectValue placeholder="Select a theme" />
        </SelectTrigger>
        <SelectContent
          style={{
            backgroundColor: skin.bgColor, // This applies your skin background color to the dropdown list
            color: skin.textColor, // This applies your skin text color to the dropdown list
            zIndex: '10000'
          }}
        >
          {promptTemplates.map((pt) => (
            <SelectItem value={pt.label} key={`template-${pt.label}`}>
              {pt.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default PromptTemplateButtons

const styleTransferTemplates = [
  {
    prompt: 'solo piano only',
    label: 'piano'
  },
  {
    prompt: 'rhodes piano only',
    label: 'rhodes'
  },
  {
    prompt: 'staccato bassline only',
    label: 'staccato bassline'
  },
  {
    prompt: 'horns only',
    label: 'horn'
  },
  {
    prompt: 'acoustic guitar only',
    label: 'acoustic guitar'
  },
  {
    prompt: 'congas only',
    label: 'congas'
  }
]

export type StyleTransferTemplate = Omit<PromptTemplate, 'key' | 'bpm' | 'samples'>

export const StyleTransferButtons = ({ onClick }: { onClick: (pt: StyleTransferTemplate) => void }) => (
  <div className="flex flex-wrap gap-4 bg-transparent">
    {styleTransferTemplates.map((pt) => {
      const { label } = pt
      return (
        <button
          key={`template-${label}`}
          title={`Use "${label}" preset`}
          onClick={() => onClick(pt)}
          className={cn('rounded bg-white px-2 text-black')}
        >
          {label}
        </button>
      )
    })}
  </div>
)
