import React from 'react'
import { ChevronRight, Play, Pause, Plus } from 'lucide-react'

interface TableViewCellProps {
  title: string
  subtitle?: string
  playing?: string | null
  onPress: () => void
  showPlay?: boolean
  onPlay?: () => void
  showAdd?: boolean
  onAdd?: () => void
  disabled?: boolean
  inSession?: boolean
  queued?: boolean
}

const TableViewCell = ({
  title,
  playing,
  subtitle,
  onPress,
  showPlay,
  onPlay,
  showAdd,
  onAdd,
  disabled = false,
  inSession = false,
  queued = false
}: TableViewCellProps) => (
  <div
    className={`flex items-center border-b border-gray-200 p-4 last:border-b-0 
    ${disabled ? 'cursor-not-allowed opacity-50' : ''}
    ${queued ? 'animate-blink' : inSession ? 'opacity-70' : 'opacity-100'}`}
    onClick={disabled ? undefined : onPress}
  >
    {showPlay && (
      <button
        onClick={(e) => {
          e.stopPropagation()
          if (!disabled && onPlay) onPlay()
        }}
        className={`mr-4 text-white hover:text-gray-400 focus:outline-none ${disabled ? 'cursor-not-allowed' : ''}`}
        aria-label="Play sample"
        disabled={disabled}
      >
        {playing === title ? <Pause size={24} /> : <Play size={24} />}
      </button>
    )}
    <div className="flex-grow">
      <div className="text-base text-white">{title}</div>
      {subtitle && <div className="text-sm text-gray-200">{subtitle}</div>}
    </div>
    {showAdd ? (
      <button
        onClick={(e) => {
          e.stopPropagation()
          if (!disabled && onAdd) onAdd()
        }}
        className={`hover:text-grey-500 text-white focus:outline-none ${disabled ? 'cursor-not-allowed' : ''}`}
        aria-label="Add sample"
        disabled={disabled}
      >
        <Plus size={24} />
      </button>
    ) : (
      <ChevronRight className="text-gray-400" size={20} />
    )}
  </div>
)

export default TableViewCell
