import { create } from 'zustand'
import { Sample, SongData } from '../types'

export interface QueuedTrack {
  songData: SongData[] | Sample[]
  title: string
  sourceBpm: number
  targetBpm: number
  durationInMilliseconds?: number
}

interface TrackQueue {
  items: QueuedTrack[]
  addItem: (item: QueuedTrack) => void
  removeItem: (index: number | number[]) => void // Updated to accept both number and array of numbers
}

export const useTrackQueue = create<TrackQueue>((set) => ({
  items: [],
  addItem: (item) =>
    set((state) => ({
      items: [...state.items, item]
    })),

  // Updated to handle both single index and array of indices
  removeItem: (index) =>
    set((state) => ({
      items: state.items.filter((_, i) => !(Array.isArray(index) ? index.includes(i) : i === index))
    }))
}))
