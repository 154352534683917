import { Search } from 'lucide-react'
import { useSkin } from '../context/skin-provider'

interface SearchBarProps {
  onSearch: (value: string) => void
  placeholder: string
}

const SearchBar = ({ onSearch, placeholder }: SearchBarProps) => {
  const { skin } = useSkin()

  return (
    <div
      className="px-4 py-2"
      style={{
        backgroundColor: skin.bgColor,
        color: skin.textColor
      }}
    >
      <div className="relative">
        <input
          type="text"
          placeholder={placeholder}
          onChange={(e) => onSearch(e.target.value)}
          className="w-full rounded-lg bg-white py-2 pl-10 pr-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
      </div>
    </div>
  )
}

export default SearchBar
