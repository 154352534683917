import { create } from 'zustand'

interface KeyState {
  key: string | undefined
  currentKey: string | undefined
  keyLocked: boolean
  setKey: (newKey: string) => void
  setCurrentKey: (newCurrentKey: string) => void
  setKeyLocked: (newKeyLockedState: boolean) => void
}

export const useKeyStore = create<KeyState>((set) => ({
  key: undefined,
  currentKey: undefined,
  keyLocked: false,
  setKey: (newKey) => set({ key: newKey }),
  setCurrentKey: (newCurrentKey) => set({ currentKey: newCurrentKey }),
  setKeyLocked: (newKeyLockedState) => set({ keyLocked: newKeyLockedState })
}))
